<template>
  <div class="app-container">
    <el-button v-permission="['MENU_ADD']" class="new_add" type="primary" @click="handleAdd">新增</el-button>
    <el-table
      v-loading="listLoading"
      :data="tableList"
      row-key="id"
      border
      :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
    >
      <el-table-column prop="title" label="title" header-align="center" align="left" />
      <el-table-column prop="path" label="路由" header-align="center" align="center" />
      <el-table-column prop="component" label="页面路径" header-align="center" align="center" />
      <el-table-column prop="hidden" label="hidden" header-align="center" align="center">
        <template slot-scope="scope">
          <el-switch
            :value="scope.row.hidden"
            active-text="是"
            inactive-text="否"
            active-value="true"
            inactive-value="false"
          />
        </template>
      </el-table-column>
      <el-table-column prop="sortNum" label="排序号" header-align="center" align="center" width="100" />

      <el-table-column label="操作" width="350" align="center">
        <template slot-scope="scope">
          <el-button
            v-permission="['MENU_EDIT']"
            type="primary"
            size="small"
            @click="handleEdit(scope.row)"
          >编辑</el-button>
          <el-button
            v-permission="['MENU_ADD_DOWN']"
            type="primary"
            size="small"
            @click="handleAdd(scope.row)"
          >添加下级菜单</el-button>
          <el-button
            v-permission="['MENU_DELETE']"
            type="danger"
            size="small"
            @click="handleDelete(scope.row)"
          >删除</el-button>
          <el-button type="primary" size="small" @click="handleAuth(scope.row)">权限管理</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :close-on-click-modal="false" :visible.sync="dialogAddVisible" :title="title">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px" label-position="left">
        <el-form-item label="路由" prop="path">
          <el-input v-model="form.path" placeholder="路由" />
        </el-form-item>
        <el-form-item label="页面路径" prop="component">
          <el-input v-model="form.component" placeholder="页面路径" />
        </el-form-item>
        <el-form-item label="hidden" prop="hidden">
          <el-switch
            v-model="form.hidden"
            active-text="是"
            inactive-text="否"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>
        <el-form-item label="排序号" prop="sortNum">
          <el-input-number v-model="form.sortNum" :min="1" :max="1000" />
        </el-form-item>

        <el-form-item label="redirect" prop="redirect">
          <el-input v-model="form.redirect" placeholder="redirect" />
        </el-form-item>
        <el-form-item label="name" prop="name">
          <el-input v-model="form.name" placeholder="name" />
        </el-form-item>
        <el-form-item label="alwaysShow" prop="alwaysShow">
          <el-switch
            v-model="form.alwaysShow"
            active-text="是"
            inactive-text="否"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>

        <el-divider content-position="left">meta</el-divider>
        <el-form-item label="title" prop="title">
          <el-input v-model="form.title" placeholder="title" />
        </el-form-item>
        <el-form-item label="icon" prop="icon">
          <el-input v-model="form.icon" placeholder="icon" />
        </el-form-item>
        <el-form-item label="noCache" prop="noCache">
          <el-switch
            v-model="form.noCache"
            active-text="是"
            inactive-text="否"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>
        <el-form-item label="breadcrumb" prop="breadcrumb">
          <el-switch
            v-model="form.breadcrumb"
            active-text="是"
            inactive-text="否"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>
        <el-form-item label="affix" prop="affix">
          <el-switch
            v-model="form.affix"
            active-text="是"
            inactive-text="否"
            active-value="true"
            inactive-value="false"
          />
        </el-form-item>
        <el-form-item label="activeMenu" prop="activeMenu">
          <el-input v-model="form.activeMenu" placeholder="activeMenu" />
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <div style="text-align:right;">
          <el-button type="primary" @click="save">保存</el-button>
          <el-button type="danger" class="quxiao_btn" @click="dialogAddVisible=false">取消</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { list, save, deteteData } from '@/api/menu'
import { Message, MessageBox } from 'element-ui'
export default {
  data() {
    return {
      title: null,
      form: {
        id: null,
        parentId: null,
        hidden: null,
        path: null,
        redirect: null,
        name: null,
        component: null,
        alwaysShow: null,
        title: null,
        icon: null,
        noCache: null,
        breadcrumb: null,
        activeMenu: null,
        affix: null,
        sortNum: null

      },
      rules: {
        path: [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        hidden: [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        title: [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        component: [
          { required: true, message: '不能为空', trigger: 'change' }
        ],
        sortNum: [
          { required: true, message: '不能为空', trigger: 'change' }
        ]
      },

      tableList: [],
      listLoading: false,
      dialogAddVisible: false

    }
  },
  created() {
    // 预加载
    this.loadList()
  },
  methods: {
    handleRootAdd() {
      for (var key in this.form) {
        this.form[key] = null
      }
      this.title = '新增'
      this.form.breadcrumb = 'true'
      this.dialogAddVisible = true
    },
    handleAdd(row) {
      for (var key in this.form) {
        this.form[key] = null
      }
      this.title = '新增'
      this.form.breadcrumb = 'true'
      this.form.hidden = 'false'
      this.form.parentId = row.id
      this.dialogAddVisible = true
    },
    handleEdit(row) { // 显示编辑页面
      this.form.breadcrumb = 'true'

      this.form.id = row.id
      this.form.parentId = row.parentId
      this.form.hidden = row.hidden
      this.form.path = row.path
      this.form.redirect = row.redirect
      this.form.name = row.name
      this.form.component = row.component
      this.form.alwaysShow = row.alwaysShow
      this.form.title = row.title
      this.form.icon = row.icon
      this.form.noCache = row.noCache
      this.form.breadcrumb = row.breadcrumb
      this.form.activeMenu = row.activeMenu
      this.form.affix = row.affix
      this.form.sortNum = row.sortNum

      this.title = '编辑'

      this.dialogAddVisible = true
    },
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })

          save(this.form).then(response => {
            Message({
              message: '操作成功',
              type: 'success',
              duration: 5 * 1000
            })
            loading.close()
            this.dialogAddVisible = false
            // 重新加载表格
            this.loadList()
          }).catch(response => {
            loading.close()
          })
        } else {
          return false
        }
      })
    },
    handleDelete(row) {
      MessageBox.confirm('确定删除吗', '确定', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        cancelButtonClass: 'qx',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        deteteData({ id: row.id }).then(response => {
          loading.close()
          this.loadList()
          Message({
            message: '操作成功',
            type: 'success',
            duration: 5 * 1000
          })
        }).catch(response => {
          loading.close()
        })
      })
    },
    loadList() { // 加载表格
      this.listLoading = true
      list(this.queryPage).then(response => {
        var data = response.data
        var list = []

        for (let i = 0; i < data.length; i++) {
          var e = data[i]
          if (e.parentId === '0') {
            list.push(e)
          }

          for (let j = 0; j < data.length; j++) {
            const e1 = data[j]
            if (e1.parentId === e.id) {
              var children = e.children
              if (!children) {
                children = []
                e.children = children
              }
              children.push(e1)
            }
          }
        }

        this.tableList = list
        this.listLoading = false
      }).catch(response => {
        this.listLoading = false
      })
    },
    handleAuth(row) {
      this.$router.push({
        path: '/permission/authorityManger', query: { menuId: row.id }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.app-container{
    height:calc(100% - 40px);
    width:calc(100% - 40px);
    padding:20px;
}
#app-contain{
    height:100%;
    width:100%;
}
.filter-container {
    position:relative;

  .filter-item {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 10px;
    margin-right: 10px;
  }
}
.pagination-container{
    padding: 10px 16px !important;
}
</style>
